import axios from "axios";
import React, { useState, useEffect } from "react";
import Data from "./Data";

function App() {


  return (
    <div className="App">

      <Data  />
    
    </div>
  );
}

export default App;
