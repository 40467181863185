import React from 'react'

const getData = async query => {
    const url = "https://serverless-api.signalnerve.workers.dev/"

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
    })

    return response.json()


}
function Data() {
    const [data, setData] = React.useState([]);
    const [query, setQuery] = React.useState("");
    React.useEffect(() => {
        fetch("https://serverless-api.saud2016.workers.dev/")
            .then(response => response.json())
            .then(json => setData(json))
        console.log("datain data", data)
    }, [])
    const updateQuery = (e) => {
        setQuery(e.target.value)
    }

    const search = async () => {
        const result = await getData(query)
        setData(result)

    }



    return (
        <div>
            <div className="input-group mb-3  mt-4 offset-3" style={{ width: "40%" }} >
                <input onChange={updateQuery} type="search" className="form-control" placeholder="Search images" />
                <button onClick={search} className="btn btn-outline-success" type="button" id="button-addon2">Search</button>
            </div>
            {
                data.map(item => (
                    
                    <a key={item.id} href={item.link} target="_blank">

                        <img src={item.image} className="mx-5 my-4" />
                    </a>

                ))
            }

        </div>
    )
}

export default Data
